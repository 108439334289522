export const getUnitInfo = async ({baseId, tableId, unitId = 519, airtableApiKey}) => {
  
    const url = `https://api.airtable.com/v0/${baseId}/${tableId}?filterByFormula={UnitId}=${unitId}`;
    const headers = {
        'Authorization': `Bearer ${airtableApiKey}`,
        'Content-Type': 'application/json',
    };

    try {
        const response = await fetch(url, { method: 'GET', headers });
        if (!response.ok) {
            throw new Error(`Error ${response.status}: ${response.statusText}`);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error("Failed to fetch unit info:", error);
        throw error; // Rethrow to handle it higher up if needed
    }
};

export const addAppointmentToAirtable = async ({appointment, baseId, airtableTableId, airtableApiKey}) => {
    try {
      const airtableRes = await fetch(`https://api.airtable.com/v0/${baseId}/${airtableTableId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${airtableApiKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: {
            Name: appointment.nom,
            Email: appointment.email,
            Date: appointment.selectedDate,
            Time: appointment.selectedDateTime,
            Phone: appointment.phoneSub.toString(),
            UnitId: appointment.id,
          },
        }),
    });
  
      if (airtableRes.ok) {
        const airtableData = await airtableRes.json();
        console.log('Appointment added to Airtable:', airtableData);
        return airtableData; // Return data for further use
      } else {
        console.error('Failed to add appointment to Airtable');
        return null;
      }
    } catch (error) {
      console.error('Error adding appointment to Airtable:', error);
      throw error; // Rethrow for external handling
    }
};
  
export const sendDataToMakeWebhook = async ({appointment, webhookUrl}) => {

    try {
        const webhookRes = await fetch(webhookUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                Name: appointment.nom,
                Email: appointment.email,
                Date: appointment.selectedDate,
                Time: appointment.selectedDateTime,
                Phone: appointment.phoneSub.toString(),
                UnitId: appointment.id,
            }),
        });

        if (webhookRes.ok) {
            const contentType = webhookRes.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                const webhookData = await webhookRes.json();
                console.log('Data sent to Make.com webhook:', webhookData);
                return webhookData; // Return data for further use
            } else {
                console.log('Response received, but it is not JSON.');
                return null; 
            }
        } else {
            console.error('Failed to send data to Make.com webhook', webhookRes.status, webhookRes.statusText);
            return null;
        }
    } catch (error) {
        console.error('Error sending data to Make.com webhook:', error);
        throw error; 
    }
};

export const getCurrentBookings = async ({ baseId, tableId, airtableApiKey }) => {

    try {
      const url = `https://api.airtable.com/v0/${baseId}/${"tblNtcRUYiEdsStU1"}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${airtableApiKey}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        
        return data.records; // Return all records
      } else {
        console.error('Failed to fetch records from Airtable:', response.statusText);
        return null;
      }
    } catch (error) {
      console.error('Error fetching records from Airtable:', error);
      throw error; // Rethrow for external handling
    }
  };
  
  //This call allows for the update of the table once the apointment is done.
export const addAppointment = async ({appointment, baseId, airtableTableId, airtableApiKey, webhookUrl}) => {
    
    try {
      const airtableResponse = await addAppointmentToAirtable({appointment, baseId, airtableTableId, airtableApiKey});
  
      if (airtableResponse) {
        try{
            await sendDataToMakeWebhook({appointment, webhookUrl});
        } catch(error){
            console.error('Error during make webhook call:', error);
        }
      }
    } catch (error) {
      console.error('Error during appointment submission:', error);
    }
};